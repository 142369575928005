// src/redux/reducers/anthropometryReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { createNewAnthropometry, fetchAnthropometryByPatientId } from '../actions/anthropometryActions';

const anthropometrySlice = createSlice({
  name: 'anthropometry',
  initialState: {
    anthropometry: [],
    loading: false,
    error: null,
    currentAnthropometry: [],
  },
  reducers: {
    setCurrentAnthropometry: (state, action) => {
      state.currentAnthropometry = action.payload;
    },
    clearAnthropometry: (state) => {
      state.currentAnthropometry = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewAnthropometry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewAnthropometry.fulfilled, (state, action) => {
        state.loading = false;
        // state.anthropometry.push(action.payload);
        state.anthropometry = [...state.anthropometry, action.payload]; // Atualizando de forma imutável
      })
      .addCase(createNewAnthropometry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAnthropometryByPatientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAnthropometryByPatientId.fulfilled, (state, action) => {
        state.loading = false;

        // Verificando se a ação trouxe dados corretamente
        if (action.payload) {
          console.log("Anthropometria recebida:", action.payload); // Adicionando o log para debug
          state.currentAnthropometry = action.payload; // Armazena os dados no estado
        } else {
          console.log("Nenhuma antropometria encontrada ou problema com os dados.");
          state.currentAnthropometry = []; // Define um array vazio caso os dados estejam faltando
        }
      })

      .addCase(fetchAnthropometryByPatientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      ;

  }

});
export const { setCurrentAnthropometry, clearAnthropometry } = anthropometrySlice.actions;
export default anthropometrySlice.reducer;