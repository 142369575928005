// src/redux/actions/appointmentActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAnthropometry, getAnthropometryByPatientId } from '../../service/api';

// Create appointment
export const createNewAnthropometry = createAsyncThunk(
  'anthropometry/createNewAnthropometry',
  async (formData) => {
    const response = await createAnthropometry(formData);
    return response.data;
  }
);


//Get Anthropometry By Patient ID
export const fetchAnthropometryByPatientId = createAsyncThunk(
  'anthropometry/fetchAnthropometryByPatientId',
  async (patient_id) => {
    const response = await getAnthropometryByPatientId(patient_id);
    return response;
  }
); 
