import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchPatients, fetchAllProfessionals } from '../../../redux/actions/relatedEntitiesActions';
import { fetchPersons } from '../../../redux/actions/personActions';
import { createNewAnthropometry } from '../../../redux/actions/anthropometryActions';
import { clearAnthropometry } from '../../../redux/slices/anthropometrySlice';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import Accordion from '../../../components/Accordion/Accordion';

import ModalForms from '../../../components/ConfirmationModal/ModalForms';

import '../styles/CreateAnthropometry.css';
import '../../../Mobile.css';
// import Escalometro from '../../../components/Escalometro/Escalometro';

const CreateAnthropometry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedPatientGender, setSelectedPatientGender] = useState('');

  // Obtendo pacientes, profissionais e estado de antropometria
  const { patients = [], loadingPatients, errorPatients } = useSelector((state) => state.relatedEntities);
  const { professionals = [], loadingProfessionals } = useSelector((state) => state.relatedEntities);
  const { persons = [], loadingPersons } = useSelector((state) => state.persons);


  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientGender, setPatientGender] = useState('');
  const [patientBirthDate, setPatientBirthDate] = useState('');
  const [loadedProfessionals, setLoadedProfessionals] = useState({});

  // Variáveis que precisam ser calculadas

  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [imc, setImc] = useState(0);
  const [bmi, setBmi] = useState(0);
  const [imcClassificacao, setImcClassificacao] = useState('');
  const [physicalActivityLevel, setPhysicalActivityLevel] = useState('');
  const [physicalActivityClassification, setPhysicalActivityClassification] = useState('');
  const [physicalActivityFrequency, setPhysicalActivityFrequency] = useState('');
  const [frequencyOfActivity, setFrequencyOfActivity] = useState('');
  const [pesoTeorico, setPesoTeorico] = useState(0);
  const [classificacaoBernadinelli, setClassificacaoBernadinelli] = useState('');
  const [porcentagemPT, setPorcentagemPT] = useState(0);
  const [classificacaoBlackburn, setClassificacaoBlackburn] = useState('');
  const [classificacaoBray, setClassificacaoBray] = useState('');
  const [kneeHeight, setKneeHeight] = useState(0);
  const [alturaEstimada, setAlturaEstimada] = useState(0);
  const [tmb, setTmb] = useState(0); //Taxa de Metabolismo Basal
  const [vet, setVet] = useState(0); // Valor Energético Total
  const [waist, setWaist] = useState(0);  // Circunferência da Cintura
  const [waistClassification, setWaistClassification] = useState('');
  const [tricepsSkinfold, setTricepsSkinfold] = useState(0);
  const [suprailiacSkinfold, setSuprailiacSkinfold] = useState('');
  const [subscapularSkinfold, setSubscapularSkinfold] = useState('');
  const [supraespinalSkinfold, setSupraespinalSkinfold] = useState('');
  const [chestSkinfold, setChestSkinfold] = useState('');
  const [bicepsSkinfold, setBicepsSkinfold] = useState('');
  const [thighSkinfold, setThighSkinfold] = useState('');
  const [calfSkinfold, setCalfSkinfold] = useState('');
  const [abdominalSkinfold, setAbdominalSkinfold] = useState('');
  const [axillarySkinfold, setAxillarySkinfold] = useState('');
  const [cmb, setCmb] = useState(0);  // Circunferência Muscular do Braço
  const [amb, setAmb] = useState(0);  // Área Muscular do Braço
  const [ambc, setAmbc] = useState(0);  // Área Muscular do Braço Corrigida
  const [selectedFatPercentageEquation, setSelectedFatPercentageEquation] = useState('');
  const [selectedPatientCategory, setSelectedPatientCategory] = useState('');
  const [selectedPatientGroup, setSelectedPatientGroup] = useState('');
  const [somaDobrasCutaneas, setSomaDobrasCutaneas] = useState(0);
  const [percentualGordura, setPercentualGordura] = useState(0);

  const [ab, setAb] = useState(0);    // Área do Braço
  const [agb, setAgb] = useState(0);   // Área Gordurosa do Braço
  const [classificacaoMusculatura, setClassificacaoMusculatura] = useState('');
  const [imcZscore, setImcZscore] = useState('');
  const [showZScoreField, setShowZScoreField] = useState(false);
  const [dum, setDum] = useState(null); //Data da Última Menstruação
  const [idadeGestacional, setIdadeGestacional] = useState(0);
  const [equationText, setEquationText] = useState('');
  const [ppgWeight, setPpgWeight] = useState('');
  const [recommendedWeightGain, setRecommendedWeightGain] = useState('');
  const [gestationalImc, setGestationalImc] = useState('');
  const [dppDate, setDppDate] = useState('');
  const [dpp, setDpp] = useState(0);

  const [pesoMassaGorda, setPesoMassaGorda] = useState('');
  const [pesoMassaCorporal, setPesoMassaCorporal] = useState('');
  const [diagnosticoMassaMuscular, setDiagnosticoMassaMuscular] = useState('');
  const [percentualIdealGordura, setPercentualIdealGordura] = useState('');
  const [percentualGorduraInterna, setPercentualGorduraInterna] = useState('');
  const [totalAguaCorporal, setTotalAguaCorporal] = useState('');
  const [massaCorporalMagra, setMassaCorporalMagra] = useState('');

  const [erroGrupoGestante, setErroGrupoGestante] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [patientYear, setPatientYear] = useState('');
  const [patientMonth, setPatientMonth] = useState('');
  const [pesoEstimado, setPesoEstimado] = useState('');
  const [pesoAtualEstimado, setPesoAtualEstimado] = useState('');

  const [abdominalCircumference, setAbdominalCircumference] = useState(0);
  const [ankleCircumference, setAnkleCircumference] = useState(0);
  const [armCircumference, setArmCircumference] = useState(0);
  const [calfCircumference, setCalfCircumference] = useState(0);
  const [chestCircumference, setChestCircumference] = useState(0);
  const [forearmCircumference, setForearmCircumference] = useState(0);
  const [glutealMaxCircumference, setGlutealMaxCircumference] = useState(0);
  const [headCircumference, setHeadCircumference] = useState(0);
  const [hipCircumference, setHipCircumference] = useState(0);
  const [thighMaxCircumference, setThighMaxCircumference] = useState(0);
  const [waistCircumference, setWaistCircumference] = useState(0);
  const [wristCircumference, setWristCircumference] = useState(0);
  const [leftArmCircumference, setLeftArmCircumference] = useState(0);
  const [rightArmCirumference, setRightArmCircumference] = useState(0);
  const [leftForeArmCircumference, setLeftForeArmCircumference] = useState(0);
  const [rightForeArmCircumference, setRightForeArmCircumference] = useState(0);
  const [leftHandCircumference, setLeftHandCircumference] = useState(0);
  const [rightHandCircumference, setRightHandCircumference] = useState(0);



  // Carregar pacientes, pessoas e profissionais ao montar o componente
  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchPersons());
    dispatch(fetchAllProfessionals());
    dispatch(clearAnthropometry());
  }, [dispatch]);
  // Atualizar o estado de loadedProfessionals quando persons ou professionals mudarem
  useEffect(() => {
    if (!loadingPersons && !loadingProfessionals && persons.length > 0 && professionals.length > 0) {
      const professionalMap = {};

      // Mapeia todos os profissionais com base no person_id
      // biome-ignore lint/complexity/noForEach: <explanation>
      persons.forEach(person => {
        if (['nutritionist', 'professional', 'doctor'].includes(person.person_type)) {
          professionalMap[person.person_id] = `${person.first_name} ${person.last_name}`;
        }
      });

      // Adiciona ou atualiza com informações adicionais dos professionals, se necessário
      // biome-ignore lint/complexity/noForEach: <explanation>
      professionals.forEach(professional => {
        if (!professionalMap[professional.person_id]) {
          professionalMap[professional.person_id] = professional.name || 'Nome não disponível';
        }
      });

      setLoadedProfessionals(professionalMap);
      console.log('Profissionais mapeados:', professionalMap);
    }
  }, [loadingPersons, loadingProfessionals, persons, professionals]);
  // Selecionando um Paciente
  const handlePatientChange = (option, setFieldValue) => {
    setFieldValue('patient_id', option ? option.value : '');

    const selectedPatient = patients.find(p => p.person_id === option.value);
    if (selectedPatient) {
      setSelectedPatient(selectedPatient);

      if (selectedPatient.gender) {
        setSelectedPatientGender(selectedPatient.gender);
        setGender(selectedPatient.gender);
        console.log(`Paciente Gênero: ${selectedPatient.gender}`);
      } else {
        console.error('Gênero do paciente não disponível');
      }
    }
    const selectedPerson = persons.find(p => p.person_id === option.value);
    if (selectedPerson) {
      setPatientBirthDate(formatDate(selectedPerson.birth_date));
      setAge(calculateAgeYear(selectedPerson.birth_date));
    }
  };
  useEffect(() => {
    if (age) {
      console.log(`Age updated: ${age}`);
    }
  }, [age]);

  const initialValues = {
    ab: '',
    abdominal_circumference: '',
    abdominal_skinfold: '',
    activity_factor_code: '',
    agb: '',
    ankle_circumference: '',
    arm_circumference: '',
    arm_muscle_area: '',
    axillary_skinfold: '',
    biceps_skinfold: '',
    imc: '',
    bmi: '',
    body_fat_percentage: '',
    calf_circumference: '',
    calf_skinfold: '',
    category_id: '',
    chest_circumference: '',
    chest_skinfold: '',
    cmb: '',
    current_weight: '',
    desired_weight: '',
    diagnosis: '',
    estimated_current_weight: '',
    estimated_height: '',
    estimated_weight: '',
    fat_calculate_equation: '',
    fat_mass_weight: '',
    femur_diameter: '',
    forearm_circumference: '',
    gi_percentage_type: '',
    gluteal_max_circumference: '',
    head_circumference: '',
    height: '',
    hip_circumference: '',
    humeral_diameter: '',
    ideal_fat_percentage: '',
    knee_height: '',
    last_period_date: '',
    lean_weight_type: '',
    left_arm: '',
    left_foot: '',
    left_forearm: '',
    left_hand: '',
    left_leg: '',
    left_lower_leg: '',
    measurement_date: '',
    measurement_type: '',
    muscle_mass_diagnosis: '',
    muscle_mass_weight: '',
    nutritionist_id: '',
    overWeight_duration: '',
    patient_group_id: '',
    patient_id: '',
    ppg_weight: '',
    physical_activity: '',
    physical_activity_frequency: '',
    physical_activity_level: '',
    ppr_time: '',
    radial_diameter: '',
    reference_estimated_weight: '',
    right_arm: '',
    right_foot: '',
    right_forearm: '',
    right_hand: '',
    right_leg: '',
    right_lower_leg: '',
    sum_skinfolds: '',
    supraespinal_skinfold: '',
    suprailiac_skinfold: '',
    subscapular_skinfold: '',
    theoretical_weight_percentage: '',
    thigh_max_circumference: '',
    thigh_skinfold: '',
    tmb: '',
    total_body_water: '',
    triceps_skinfold: '',
    usual_weight: '',
    vet: '',
    waist_circumference: '',
    wrist_circumference: ''
  };


  const validationSchema = Yup.object({
    patient_id: Yup.number().required('Paciente é necessário'),
    nutritionist_id: Yup.number().required('Nutricionista é necessário'),
    height: Yup.number().required('Altura é necessária').positive('Altura deve ser positiva'),
    current_weight: Yup.number().required('Peso atual é necessário').positive('Peso deve ser positivo'),
    measurement_date: Yup.date().required('Data da medição é necessária'),
    patient_group_id: Yup.string().required('Grupo do Paciente é necessário'),
    physical_activity_frequency: Yup.string().required('Frequência da Atividade é necessário'),
    physical_activity_level: Yup.string().required('Nível da Atividade é necessário'),
    knee_height: Yup.number().required('Altura do Joelho é necessário'),
    fat_calculate_equation: Yup.number().required('Definir uma fórmula é necessário'),
    arm_circumference: Yup.number().required('Circunferência do Braço é necessário'),
    subscapular_skinfold: Yup.number().required('Dobra Subescapular é necessário'),
    chest_skinfold: Yup.number().required('Dobra Peitoral é necessário'),
    triceps_skinfold: Yup.number().required('Dobra Trícepes é necessário'),
    biceps_skinfold: Yup.number().required('Dobra Bicepes é necessário'),
    thigh_skinfold: Yup.number().required('Dobra Coxa é necessário'),
    calf_skinfold: Yup.number().required('Dobra Panturrilha é necessário'),
    abdominal_skinfold: Yup.number().required('Dobra Abdominal é necessário'),
    axillary_skinfold: Yup.number().required('Dobra Axillary é necessário'),
  });


  // ......................................Funções da Antropometria
  // Função para calcular a Idade Anos com useCallback
  const calculateAgeYear = useCallback((patientBirthDate) => {
    const today = new Date();
    const birthDate = new Date(patientBirthDate);
    let years = today.getFullYear() - birthDate.getFullYear();
    const months = today.getMonth() - birthDate.getMonth();
    const days = today.getDate() - birthDate.getDate();

    // Ajuste para meses e dias se o aniversário ainda não foi completado no ano corrente
    if (months < 0 || (months === 0 && days < 0)) {
      years--;
    }
    return years; // Retorna apenas os anos
  }, []);

  // Função para calcular a idade em Anos, Meses e Dias
  const calculateAge = useCallback((patientBirthDate) => {
    const today = new Date();
    const birthDate = new Date(patientBirthDate);

    // Anos
    let years = today.getFullYear() - birthDate.getFullYear();

    // Meses
    let months = today.getMonth() - birthDate.getMonth();

    // Dias
    let days = today.getDate() - birthDate.getDate();

    // Ajuste para meses e anos se o aniversário ainda não foi completado no ano corrente
    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    // Ajuste para dias se o dia atual for anterior ao dia de nascimento
    if (days < 0) {
      const previousMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Último dia do mês anterior
      days += previousMonth.getDate();
      months--;
    }

    // Ajuste final para meses negativos
    if (months < 0) {
      months += 12;
      years--;
    }

    return { years, months, days };
  }, []);

  // Função para calcular o IMC memoizada
  const calcularIMC = useCallback((height, weight) => {
    if (!height || !weight) {
      console.error("Altura ou peso não fornecidos corretamente");
      return Number.NaN;
    }
    const imc = weight / (height * height);
    return imc.toFixed(2);
  }, []);
  // // Função para classificar o IMC com useCallback
  // const classificarIMC = useCallback((imc) => {
  //   if (imc < 16.00) return "Magreza Grau III";
  //   if (imc >= 16.00 && imc <= 16.99) return "Magreza Grau II";
  //   if (imc >= 17.00 && imc <= 18.49) return "Magreza Grau I";
  //   if (imc >= 18.50 && imc <= 24.99) return "Eutrófico";
  //   if (imc >= 25.00 && imc <= 29.99) return "Pré-obeso";
  //   if (imc >= 30.00 && imc <= 34.99) return "Obesidade Grau I";
  //   if (imc >= 35.00 && imc <= 39.99) return "Obesidade Grau II";
  //   if (imc >= 40.00) return "Obesidade Grau III";
  //   return "Indeterminado";
  // }, []);
  // Função para classificar IMC de idosos com useCallback
  const classificarIMCIdosos = useCallback((imc) => {
    if (imc < 22) return "Baixo Peso";
    if (imc >= 22 && imc <= 27) return "Eutrofia";
    if (imc > 27) return "Sobrepeso";
    return "Indeterminado";
  }, []);
  // Função para classificar o IMC para crianças com base no WHO 2006/2007 com useCallback
  const classificarIMCCriancas = useCallback((imcZscore) => {
    if (imcZscore > 2) return 'Sobrepeso';
    if (imcZscore >= -2 && imcZscore <= 2) return 'Peso adequado';
    if (imcZscore < -2) return 'Baixo peso';
    return 'Indeterminado';
  }, []);
  // Função para classificar IMC com base no grupo do paciente
  const classificarIMC = useCallback((imc, imcZscore, patientGroup) => {
    if (patientGroup === '2') { // Criança
      if (!imcZscore) {
        return "z-Score não informado"; // Exibe uma mensagem se o z-score não for fornecido
      }
      return classificarIMCCriancas(imcZscore);
    }

    if (patientGroup === '4') {  // Idoso
      return classificarIMCIdosos(imc);
    }

    // Classificação padrão para Adultos e outros grupos
    if (imc < 16.00) return "Magreza Grau III";
    if (imc >= 16.00 && imc <= 16.99) return "Magreza Grau II";
    if (imc >= 17.00 && imc <= 18.49) return "Magreza Grau I";
    if (imc >= 18.50 && imc <= 24.99) return "Eutrófico";
    if (imc >= 25.00 && imc <= 29.99) return "Pré-obeso";
    if (imc >= 30.00 && imc <= 34.99) return "Obesidade Grau I";
    if (imc >= 35.00 && imc <= 39.99) return "Obesidade Grau II";
    if (imc >= 40.00) return "Obesidade Grau III";
    return "Indeterminado";
  }, [classificarIMCCriancas, classificarIMCIdosos]);
  // Função para Classificar o Fator de Atividade com useCallback - FAO/OMS de 1985:
  const classificarFatorAtividade = useCallback((physicalActivityLevel, gender) => {
    if (gender === 'male') {
      if (physicalActivityLevel === "light") return 1.55;    // Atividade física leve
      if (physicalActivityLevel === "moderate") return 1.78; // Atividade física moderada
      if (physicalActivityLevel === "intense") return 2.10;  // Atividade física intensa
    } else if (gender === 'female') {
      if (physicalActivityLevel === "light") return 1.56;    // Atividade física leve
      if (physicalActivityLevel === "moderate") return 1.64; // Atividade física moderada
      if (physicalActivityLevel === "intense") return 1.82;  // Atividade física intensa
    }

    console.error("Fator de atividade ou gênero inválido");
    return 0; // Retorna 0 se não for possível classificar
  }, []);
  // Função para Classificar a Freqêuncia de Atividade com useCallback - Valores Baseados no CDC (Centers for Disease Control and Prevention) e ACSM (American College of Sports Medicine):
  const classificarNivelDeAtividade = useCallback((frequencyOfActivity) => {
    if (frequencyOfActivity === "unable") return 1.2;  // Sedentário (pouca ou nenhuma atividade física)
    if (frequencyOfActivity === "sedentary") return 1.375;  // Leve  (exercício leve ou atividade leve 1-3 dias por semana)
    if (frequencyOfActivity === "occasional") return 1.55;  // Moderado (exercício moderado 3-5 dias por semana)
    if (frequencyOfActivity === "frequent") return 1.725;  // Ativo (exercício intenso 6-7 dias por semana)
    if (frequencyOfActivity === "regular") return 1.9;  // Muito Ativo  (exercícios muito intensos ou atividade física pesada)

    console.error("Frequência de atividade inválida");
    return 0; // Retorna 0 se não for possível classificar
  }, []);
  // Função para calcular o Peso Teórico com useCallback
  const calcularPesoTeorico = useCallback((height, gender) => {
    const basePeso = height - 100;
    let porcentagem = 0;

    if (gender === 'male') {
      if (height >= 140 && height <= 155) {
        porcentagem = 0;  // Brevelíneo
      } else if (height > 155 && height <= 170) {
        porcentagem = 5;  // Normolíneo
      } else if (height > 170) {
        porcentagem = 10;  // Longelíneo
      }
    } else if (gender === 'female') {
      if (height >= 140 && height <= 150) {
        porcentagem = 0;  // Brevelínea
      } else if (height > 150 && height <= 165) {
        porcentagem = 10;  // Normolínea
      } else if (height > 165) {
        porcentagem = 15;  // Longelínea
      }
    }

    return basePeso - (basePeso * porcentagem / 100);  // PT = (Altura - 100) - % Biotipo
  }, []);
  // Função para classificar o biotipo com useCallback (Bernadinelli)
  const classificarBiotipo = useCallback((height, gender) => {
    console.log(`Classificar Biotipo com gênero: ${gender}`);
    if (gender === 'male') {
      if (height >= 140 && height <= 155) {
        return 'Brevelíneo';
      } if (height > 155 && height <= 170) {
        return 'Normolíneo';
      } if (height > 170) {
        return 'Longelíneo';
      }
    } else if (gender === 'female') {
      if (height >= 140 && height <= 150) {
        return 'Brevelínea';
      } if (height > 150 && height <= 165) {
        return 'Normolínea';
      } if (height > 165) {
        return 'Longelínea';
      }
    }
    return 'Indeterminado';
  }, []);
  // Função para classificar com base na % do Peso Teórico (Tabela de Blackburn) com useCallback
  const classificarBlackburn = useCallback((porcentagemPT) => {
    if (porcentagemPT >= 91) {
      return 'Eutrófica';
    } if (porcentagemPT >= 80 && porcentagemPT <= 90) {
      return 'Depleção leve';
    } if (porcentagemPT >= 70 && porcentagemPT <= 79) {
      return 'Depleção moderada';
    } if (porcentagemPT < 70) {
      return 'Depleção grave';
    }
    return 'Indeterminado';
  }, []);
  // Função para classificar pela OMS (George A. Bray)
  const classificarBray = useCallback((bmi) => {
    if (bmi < 16) return 'Baixo peso grau III';
    if (bmi >= 16 && bmi <= 16.9) return 'Baixo peso grau II';
    if (bmi >= 17 && bmi <= 18.4) return 'Baixo peso grau I';
    if (bmi >= 18.5 && bmi <= 24.9) return 'Eutrófico';
    if (bmi >= 25 && bmi <= 29.9) return 'Sobrepeso';
    if (bmi >= 30 && bmi <= 34.9) return 'Obesidade grau I';
    if (bmi >= 35 && bmi <= 39.9) return 'Obesidade grau II';
    if (bmi >= 40) return 'Obesidade grau III';
    return 'Indeterminado';
  }, []);

  // Função para calcular a altura estimada com useCallback
  const calcularAlturaEstimada = useCallback((age, alturaJoelho, gender) => {
    if (gender === 'male') {
      return (64.19 - (0.04 * age) + (2.02 * alturaJoelho)).toFixed(2);
    }
    if (gender === 'female') {
      return (84.88 - (0.24 * age) + (1.83 * alturaJoelho)).toFixed(2);
    }
    return 'Indeterminado';
  }, []);
  // Função para calcular a TMB (Taxa de Metabolismo Basal) com useCallback
  const calcularTMB = useCallback((gender, theoreticalWeight, currentWeight, heightInMeters, age) => {
    console.log(`Cálculo TMB com gênero: ${gender}`);
    const pesoParaTMB = age <= 10 ? currentWeight : theoreticalWeight; // Usa peso atual até 10 anos, teórico após 10 anos

    console.log(`Peso: ${theoreticalWeight}, Altura: ${heightInMeters}, Idade: ${age}, Gênero: ${gender}`);

    if (!theoreticalWeight || !heightInMeters || !age || Number.isNaN(theoreticalWeight) || Number.isNaN(heightInMeters) || Number.isNaN(age)) {
      console.error("Valores inválidos para o cálculo de TMB");
      return 0;
    }

    let tmb = 0;
    if (gender === 'male') {
      tmb = 88.36 + (13.4 * pesoParaTMB) + (4.8 * heightInMeters * 100) - (5.7 * age);
      console.log(`TMB calculado: ${tmb}`);
    } else if (gender === 'female') {
      tmb = 447.6 + (9.2 * pesoParaTMB) + (3.1 * heightInMeters * 100) - (4.3 * age);
      console.log(`TMB calculado: ${tmb}`);
    } else {
      console.error("Gênero inválido para cálculo de TMB");
      return 0;
    }

    return tmb.toFixed(2); // Retorna o valor arredondado
  }, []);
  // Função para calcular o VET (Valor Energético Total) com useCallback
  const calcularVET = useCallback((tmb, physicalActivityLevel) => {
    if (!tmb || Number.isNaN(tmb)) {
      console.error("TMB inválido para cálculo de VET");
      return 0;
    }
    if (!physicalActivityLevel || Number.isNaN(physicalActivityLevel)) {
      console.error("Nível de atividade inválido para cálculo de VET");
      return 0;
    }

    const vet = tmb * physicalActivityLevel;
    console.log(`Calculando VET: TMB = ${tmb}, Nível de Atividade = ${physicalActivityLevel}, VET = ${vet}`);
    return vet.toFixed(2);
  }, []);
  // Função para classificar o risco de doenças cardiovasculares com useCallback 
  const classificarCircunferenciaCintura = useCallback((waist, gender) => {
    const waistNumber = Number(waist); // Converter para número
    if (gender === 'male') {
      if (waistNumber < 94) {
        return 'Sem risco';
      }
      if (waistNumber >= 94 && waistNumber <= 102) {
        return 'Risco Elevado';
      }
      if (waistNumber > 102) {
        return 'Risco Muito Elevado';
      }
    } else if (gender === 'female') {
      if (waistNumber < 80) {
        return 'Sem risco';
      }
      if (waistNumber >= 80 && waistNumber <= 88) {
        return 'Risco Elevado';
      }
      if (waistNumber > 88) {
        return 'Risco Muito Elevado';
      }
    }
    return 'Indeterminado';
  }, []);
  // Função para calcular Circunferência Muscular do Braço (CMB) com useCallback
  const calcularCMB = useCallback((cb, pct) => {
    const pi = Math.PI;

    const cbNumber = Number(cb);
    const pctNumber = Number(pct) / 10; // Converter pct para cm

    if (cbNumber <= 0 || pctNumber <= 0 || isNaN(cbNumber) || isNaN(pctNumber)) {
      return 'N/A';  // Entradas inválidas
    }

    const cmb = cbNumber - (pi * pctNumber);

    if (cmb <= 0) {
      return 'Valor inválido';  // Valor inválido de CMB
    }

    return cmb.toFixed(2);
  }, []);
  // Função para calcular Área Muscular do Braço (AMB) com useCallback
  const calcularAMB = useCallback((cb, pct) => {
    const pi = Math.PI;

    const cbNumber = Number(cb);
    const pctNumber = Number(pct) / 10; // Convertendo para cm

    if (!cbNumber || !pctNumber || isNaN(cbNumber) || isNaN(pctNumber)) {
      return 'N/A';  // Entradas inválidas
    }

    const cmb = cbNumber - (pi * pctNumber);

    if (cmb <= 0) {
      return 'Valor inválido';
    }

    const amb = (cmb * cmb) / (4 * pi);
    return amb.toFixed(2);
  }, []);
  // Função para calcular Área Muscular do Braço Corrigida (AMBc) com useCallback
  const calcularAMBc = useCallback((cb, pct, gender) => {
    const pi = Math.PI;
    const cbNumber = Number(cb);
    const pctNumber = Number(pct) / 10; // Convertendo para cm

    if (!cbNumber || !pctNumber || isNaN(cbNumber) || isNaN(pctNumber)) {
      return 'N/A';  // Entradas inválidas
    }

    const baseCalc = cbNumber - (pi * pctNumber);

    if (gender === 'male') {
      return ((baseCalc * baseCalc) / (4 * pi) - 10).toFixed(2);
    }

    return ((baseCalc * baseCalc) / (4 * pi) - 6.5).toFixed(2);
  }, []);
  // Função para calcular a soma das dobras cutâneas
  const calcularSomaDobrasCutaneas = useCallback((dobras, selectedFatPercentageEquation) => {
    let soma = 0;

    switch (selectedFatPercentageEquation) {
      case '1': // Jackson e Pollock - 3 Dobras (1978)
        soma = Number(dobras.triceps) + Number(dobras.suprailiac) + Number(dobras.thigh);
        break;

      case '2': // Jackson e Pollock - 7 Dobras (1978)
        soma = Number(dobras.triceps) + Number(dobras.subscapular) + Number(dobras.chest) +
          Number(dobras.axillary) + Number(dobras.abdominal) + Number(dobras.thigh) +
          Number(dobras.calf);
        break;

      case '3': // Petrosky - 4 Dobras (1995)
        soma = Number(dobras.triceps) + Number(dobras.suprailiac) + Number(dobras.subscapular) +
          Number(dobras.thigh);
        break;

      case '4': // Durnin e Womersley - 4 Dobras (1974)
        soma = Number(dobras.triceps) + Number(dobras.biceps) + Number(dobras.subscapular) +
          Number(dobras.suprailiac);
        break;

      default:
        return 'Equação não encontrada';
    }

    return soma.toFixed(2);
  }, []);
  // Função para calcular o percentual de gordura com base na equação selecionada
  const calcularPercentualGordura = useCallback((gender, somaDobras, age, selectedFatPercentageEquation) => {
    let densidadeCorporal = 0;

    switch (selectedFatPercentageEquation) {
      case '1': // Jackson e Pollock - 3 Dobras (1978)
        if (gender === 'male') {
          densidadeCorporal = 1.10938 - (0.0008267 * somaDobras) + (0.0000016 * somaDobras ** 2) - (0.0002574 * age);
        } else {
          densidadeCorporal = 1.0994921 - (0.0009929 * somaDobras) + (0.0000023 * somaDobras ** 2) - (0.0001392 * age);
        }
        break;

      case '2': // Jackson e Pollock - 7 Dobras (1978)
        if (gender === 'male') {
          densidadeCorporal = 1.112 - (0.00043499 * somaDobras) + (0.00000055 * somaDobras ** 2) - (0.00028826 * age);
        } else {
          densidadeCorporal = 1.097 - (0.00046971 * somaDobras) + (0.00000056 * somaDobras ** 2) - (0.00012828 * age);
        }
        break;

      case '3': // Petrosky - 4 Dobras (1995)
        if (gender === 'male') {
          densidadeCorporal = 1.112 - (0.00043499 * somaDobras) + (0.00000055 * somaDobras ** 2) - (0.00028826 * age);
        } else {
          densidadeCorporal = 1.097 - (0.00046971 * somaDobras) + (0.00000056 * somaDobras ** 2) - (0.00012828 * age);
        }
        break;

      case '4': // Durnin e Womersley - 4 Dobras (1974)
        if (gender === 'male') {
          densidadeCorporal = 1.17136 - (0.06706 * Math.log10(somaDobras));
        } else {
          densidadeCorporal = 1.1665 - (0.07063 * Math.log10(somaDobras));
        }
        break;

      default:
        return 'Indefinido';
    }

    // Percentual de gordura usando a equação de Siri
    const percentualGordura = (495 / densidadeCorporal) - 450;
    return percentualGordura.toFixed(2);
  }, []);
  // Função para calcular Área do Braço (AB) com useCallback
  const calcularAB = useCallback((cb) => {
    const pi = Math.PI;

    // Calculando a Área do Braço corretamente, sem multiplicar cb por 10
    const ab = (pi * Math.pow(cb, 2)) / 4;

    return ab.toFixed(2); // Retorna com 2 casas decimais
  }, []);
  // Função para calcular Área Gordurosa do Braço (AGB) com useCallback
  const calcularAGB = useCallback((ab, amb) => {
    return (ab - amb).toFixed(2); // Subtração da Área Muscular do Braço
  }, []);
  // Função para classificar a musculatura com useCallback
  const classificarMusculatura = useCallback((amb, gender) => {
    if (gender === 'male') {
      if (amb <= 35) return 'Musculatura baixa';
      if (amb > 35 && amb <= 45) return 'Abaixo da média';
      if (amb > 45 && amb <= 60) return 'Média';
      if (amb > 60 && amb <= 75) return 'Acima da média';
      if (amb > 75) return 'Musculatura elevada';
    } else {
      if (amb <= 20) return 'Musculatura baixa';
      if (amb > 20 && amb <= 25) return 'Abaixo da média';
      if (amb > 25 && amb <= 35) return 'Média';
      if (amb > 35 && amb <= 55) return 'Acima da média';
      if (amb > 55) return 'Musculatura elevada';
    }
    return 'Indeterminado';
  }, []);
  // Função para calcular a Idade Gestacional (IG)
  const calcularIdadeGestacional = useCallback((dum) => {
    if (!dum) return null;

    const hoje = new Date();
    const dataDUM = new Date(dum);

    if (isNaN(dataDUM.getTime())) {
      console.error('Data de DUM inválida');
      return null;
    }

    // Calcula a diferença em semanas
    const diferencaTempo = hoje - dataDUM;
    const idadeGestacional = Math.floor(diferencaTempo / (1000 * 60 * 60 * 24 * 7)); // Convertendo milissegundos para semanas

    return idadeGestacional;
  }, []);
  // Função para calcular a Data Prevista do Parto (DPP)
  const calcularDPP = useCallback((dum) => {
    if (!dum) return null;

    const dataDUM = new Date(dum);
    const dataDPP = new Date(dataDUM.setDate(dataDUM.getDate() + 280)); // Adiciona 280 dias (40 semanas)

    return formatDate(dataDPP) // Formata a data para o formato local
  }, []);
  //Função para Calcular a Idade Gestacional e a Data Prevista do Parto
  const handleGestanteCalculations = useCallback((dum) => {
    if (selectedPatientGroup === '5') {  // Verifica se o grupo é "Gestante"
      const idadeGestacional = calcularIdadeGestacional(dum);
      setIdadeGestacional(idadeGestacional);  // Define a Idade Gestacional no estado

      const dppCalculada = calcularDPP(dum);
      setDpp(dppCalculada);  // Define a DPP no estado
    }
  }, [selectedPatientGroup, calcularIdadeGestacional, calcularDPP]);
  //Função para Calcular Massa Gorda
  const calcularPesoMassaGorda = useCallback((weight, percentualGordura) => {
    return (weight * (percentualGordura / 100)).toFixed(2);
  }, []);
  //Função para calcular Peso da Massa Corporal (Weight Body Mass)
  const calcularPesoMassaCorporal = useCallback((weight, pesoMassaGorda) => {
    return (weight - pesoMassaGorda).toFixed(2);
  }, []);
  // Função para calcular Percentual Ideal de Gordura com base na idade e gênero
  const calcularPercentualIdealGordura = useCallback((age, gender) => {
    if (gender === 'male') {
      if (age < 30) return { min: 8, max: 15 };      // Para homens com menos de 30 anos
      if (age >= 30 && age < 50) return { min: 11, max: 17 }; // Para homens entre 30 e 50 anos
      return { min: 12, max: 19 };                    // Para homens com 50 anos ou mais
    }
    if (age < 30) return { min: 15, max: 23 };        // Para mulheres com menos de 30 anos
    if (age >= 30 && age < 50) return { min: 18, max: 25 }; // Para mulheres entre 30 e 50 anos
    return { min: 20, max: 28 };                      // Para mulheres com 50 anos ou mais
  }, []);
  //Função para classificar Tipo de Percentual de Gordura Interna GI
  const classificarPercentualGI = useCallback((percentualGordura) => {
    return percentualGordura > 25 ? 'Alto' : 'Normal';
  }, []);
  //Calcular Diagnostico de Massa Muscular
  const calcularDiagnostico = useCallback((imc, percentualGordura, classificacaoMusculatura) => {
    if (imc > 30) {
      return 'Obesidade';
    } if (percentualGordura > 25) {
      return 'Excesso de gordura';
    } if (classificacaoMusculatura === 'Abaixo da média') {
      return 'Baixa massa muscular';
    }
    return 'Normal';
  }, []);
  // Função para calcular o Peso da Massa Magra (MCM)
  const calcularPesoMassaMagra = useCallback((weight, pesoMassaGorda) => {
    return (weight - pesoMassaGorda).toFixed(2);
  }, []);
  // Função Calcular Total Corporal de Água
  const calcularTotalCorporalAgua = useCallback((massaCorporalMagra) => {
    return (massaCorporalMagra * 0.73).toFixed(2); // Aproximadamente 73% da massa magra é água
  }, []);

  useEffect(() => {
    const heightInCm = Number.parseFloat(height || 0);
    const heightInMeters = (heightInCm / 100).toFixed(2); // Convertendo de centímetros para metros  com até 2 casas decimais
    const currentWeight = Number.parseFloat(weight || 0).toFixed(2); // Peso atual
    const theoreticalWeight = Number(pesoTeorico).toFixed(2);
    const alturaJoelho = Number(kneeHeight).toFixed(2);
    const cb = Number(armCircumference).toFixed(2); // circunferência do braço
    const pct = Number(tricepsSkinfold).toFixed(2); // prega cutanea tricipial

    // Dados das dobras cutâneas
    const dobras = {
      triceps: tricepsSkinfold,
      suprailiac: suprailiacSkinfold,
      subscapular: subscapularSkinfold,
      chest: chestSkinfold,
      axillary: axillarySkinfold,
      abdominal: abdominalSkinfold,
      thigh: thighSkinfold,
      calf: calfSkinfold,
      biceps: bicepsSkinfold,
    };

    if ((gender === 'male' && selectedPatientGroup === '5') || (gender === 'male' && selectedPatientGroup === '6')) {
      // Caso o paciente seja masculino e o grupo seja Gestante
      setErroGrupoGestante("O Gênero do Paciente selecionado não pode ser Gestante / Lactante.");

      // Reseta os campos de gestante
      setDum(null);
      setIdadeGestacional(null);
      setDpp(null);
      setGestationalImc(null);
      setPpgWeight(null);
      setRecommendedWeightGain(null);

    } else {
      // Se o paciente não for do grupo Gestante ou gênero não for masculino, limpar a mensagem de erro
      setErroGrupoGestante('');
    }

    const patientGroup = selectedPatientGroup;
    setSelectedPatientGroup(patientGroup);

    const patientCategory = selectedPatientCategory;
    setSelectedPatientCategory(patientCategory)

    // Calcular IMC
    const calculatedImc = calcularIMC(heightInMeters, currentWeight);
    setImc(calculatedImc);  // Define o valor do IMC no estado
    setBmi(calculatedImc);  // Define o valor do IMC no estado

    // // Classificar o IMC
    // const imcClassification = classificarIMC(calculatedImc);
    // setImcClassificacao(imcClassification);  // Define a classificação do IMC no estado

    // Classificar o IMC de acordo com o grupo do paciente
    const imcClassification = classificarIMC(imc, imcZscore, selectedPatientGroup);
    setImcClassificacao(imcClassification);  // Define a classificação do IMC no estado

    // Nível de atividade física
    const physicalActivityLevelValue = classificarNivelDeAtividade(frequencyOfActivity) || 0;
    setPhysicalActivityClassification(physicalActivityLevelValue);

    //Frequência de atividade
    const activityFactor = classificarFatorAtividade(physicalActivityLevel, gender) || 0; // Valor padrão é 0 se não houver correspondência
    setPhysicalActivityFrequency(activityFactor)

    // Peso Teórico
    const calculatedPesoTeorico = calcularPesoTeorico(height, gender);
    setPesoTeorico(calculatedPesoTeorico);

    // Classificação Bernadinelli (Biotipo)
    const biotipoClassificacao = classificarBiotipo(heightInCm, gender);
    setClassificacaoBernadinelli(biotipoClassificacao);

    // Porcentagem PT e Classificação Blackburn
    const calculatedPorcentagemPT = (currentWeight / theoreticalWeight * 100).toFixed(2);
    setPorcentagemPT(calculatedPorcentagemPT);
    setClassificacaoBlackburn(classificarBlackburn(calculatedPorcentagemPT));

    // Classificar pela OMS (George A. Bray)
    const classificacaoBray = classificarBray(bmi);
    setClassificacaoBray(classificacaoBray);

    // Altura estimada (baseada na fórmula Chumlea)
    const alturaEstimadaCalculada = calcularAlturaEstimada(age, alturaJoelho, gender);
    setAlturaEstimada(alturaEstimadaCalculada);

    // TMB - Taxa do Metabolismo Basal
    const calculatedTmb = calcularTMB(gender, theoreticalWeight, currentWeight, heightInMeters, age);
    setTmb(calculatedTmb);

    // VET Valor Energético Total
    const calculatedVet = calcularVET(calculatedTmb, physicalActivityLevelValue);
    setVet(calculatedVet);

    // Classificar Cirunferência da Cintura
    const waistCircumference = classificarCircunferenciaCintura(waist, gender)
    setWaistClassification(waistCircumference)

    // Circunferência Muscular do Braço (CMB)
    const calculatedCMB = calcularCMB(cb, pct);
    setCmb(calculatedCMB);

    // Área Muscular do Braço (AMB)
    const calculatedAMB = calcularAMB(cb, pct);
    setAmb(calculatedAMB);

    // Área Muscular do Braço Corrigida (AMBc)
    const calculatedAMBc = calcularAMBc(cb, pct, gender);
    setAmbc(calculatedAMBc);

    //Calcular Soma das dobras Cutâneas
    const somaDobras = calcularSomaDobrasCutaneas(dobras, selectedFatPercentageEquation);
    setSomaDobrasCutaneas(somaDobras);

    // Cálculo do percentual de Gordura
    if (selectedFatPercentageEquation && somaDobras && age && gender) {
      const percentualGorduraCalculado = calcularPercentualGordura(gender, somaDobras, age, selectedFatPercentageEquation);
      setPercentualGordura(percentualGorduraCalculado);
    }

    // Área do Braço (AB)
    const calculatedAB = calcularAB(armCircumference); // Circunferência do braço
    setAb(calculatedAB);

    // Área Gordurosa do Braço (AGB)
    const calculatedAGB = calcularAGB(calculatedAB, calculatedAMB);// Usando AMB calculada anteriormente
    setAgb(calculatedAGB);

    // Classificação da musculatura
    const musculaturaClassificacao = classificarMusculatura(calculatedAMB, gender);
    setClassificacaoMusculatura(musculaturaClassificacao);

    // Calcular Data Prevista do Parto (DPP) e Idade Gestacional (IG) se o grupo for "Gestante"
    if (selectedPatientGroup === '5') {
      const dppCalculada = calcularDPP(dum);
      const idadeGestacional = calcularIdadeGestacional(dum);
      setDppDate(dppCalculada);
      setIdadeGestacional(idadeGestacional);
    }

    // Calcular IMC gestacional
    if (ppgWeight && heightInMeters) {
      const imcGestacional = (ppgWeight / (heightInMeters ** 2)).toFixed(2);
      setGestationalImc(imcGestacional);
    }

    // Peso da Massa Gorda
    const fatMassWeight = calcularPesoMassaGorda(height, percentualGordura);
    setPesoMassaGorda(fatMassWeight);

    //Peso da Massa Corporal
    const weightMassBody = calcularPesoMassaCorporal(weight, pesoMassaGorda);
    setPesoMassaCorporal(weightMassBody);

    // Calcular o Percentual Ideal de Gordura
    const idealFatPercentage = calcularPercentualIdealGordura(age, gender);

    // Exibindo o resultado como um intervalo
    if (idealFatPercentage) {
      setPercentualIdealGordura(`${idealFatPercentage.min}% - ${idealFatPercentage.max}%`);
    }

    //Classificar Percentual de Gordura Interna
    const internalFatPercentage = classificarPercentualGI(percentualGordura);
    setPercentualGorduraInterna(internalFatPercentage);

    //Diaginostico da Massa Muscular
    const massMuscleDiagnostic = calcularDiagnostico(imc, percentualGordura, classificacaoMusculatura);
    setDiagnosticoMassaMuscular(massMuscleDiagnostic);

    //Calcular o Peso da Massa Magra Corporal
    const leanMassBody = calcularPesoMassaMagra(weight, pesoMassaGorda);
    setMassaCorporalMagra(leanMassBody);

    //Calcular Total de Água Corporal
    const totalWaterBody = calcularTotalCorporalAgua(massaCorporalMagra);
    setTotalAguaCorporal(totalWaterBody);

    //Calcular anos meses e dias do paciente
    const calculatedPatientAge = calculateAge(patientBirthDate);
    if (calculatedPatientAge) {
      setPatientYear(calculatedPatientAge.years);
      setPatientMonth(calculatedPatientAge.months);
    }

  }, [height, weight, calcularIMC, classificarIMC, classificarNivelDeAtividade, calcularPesoTeorico, physicalActivityLevel, classificarFatorAtividade, frequencyOfActivity, classificarBiotipo, pesoTeorico, classificarBlackburn, kneeHeight, calcularAlturaEstimada, age, calcularTMB, selectedPatientGroup, selectedPatientCategory, calcularVET, classificarCircunferenciaCintura, waist, armCircumference, tricepsSkinfold, calcularCMB, calcularAMB, calcularAMBc, gender, selectedFatPercentageEquation, suprailiacSkinfold, subscapularSkinfold, chestSkinfold, axillarySkinfold, abdominalSkinfold, thighSkinfold, calfSkinfold, bicepsSkinfold, calcularSomaDobrasCutaneas, calcularPercentualGordura, calcularAB, calcularAGB, classificarMusculatura, imc, imcZscore, ppgWeight, dum, calcularDPP, calcularIdadeGestacional, calcularPesoMassaGorda, percentualGordura, calcularPesoMassaCorporal, pesoMassaGorda, calcularPercentualIdealGordura, classificarPercentualGI, calcularDiagnostico, classificacaoMusculatura, calcularPesoMassaMagra, calcularTotalCorporalAgua, massaCorporalMagra, calculateAge, patientBirthDate, classificarBray, bmi]);

  // Selecionar o paciente
  const selectOptions = (items, labelKey = 'name') => items.map(item => ({
    value: item.person_id,
    label: item[labelKey],
  }));

  if (loadingPatients) {
    return <div>Carregando...</div>;
  }

  if (errorPatients) {
    return <div>Erro ao carregar dados: {errorPatients}</div>;
  }
  // apenas números nos campos
  function onlyNumbers(e, setFieldValue, fieldName) {
    const { value } = e.target;

    // Se o usuário digitar uma vírgula, substituímos por um ponto
    if (e.key === ',') {
      e.preventDefault(); // Evita que a vírgula seja inserida
      setFieldValue(fieldName, `${value}.`); // Adiciona o ponto no lugar da vírgula
      return;
    }

    // Permitir números, backspace, delete, setas, ponto e tab
    if (
      !(e.key >= '0' && e.key <= '9') && // Números
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== '.' &&
      e.key !== 'Tab'
    ) {
      e.preventDefault();
      return;
    }

    // Verificar se já existe um ponto e evitar outro
    if (e.key === '.' && value.includes('.')) {
      e.preventDefault();
      return;
    }

    // Limitar a duas casas decimais
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      if (decimalPart.length >= 2 && e.key !== 'Backspace' && e.key !== 'Delete') {
        e.preventDefault(); // Bloquear mais de duas casas decimais
      }
    }
  }
  // não permitir envio do form ao pressionar enter
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  // Função para formatar a Data DD/MM/YYYY
  const formatDate = (patientBirthDate) => {
    if (!patientBirthDate) return 'Data inválida';
    const date = new Date(patientBirthDate);
    if (Number.isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year} `;
  };
  // Função para converter data DD/MM/YYYY para YYYY-MM-DD
  function convertDateForDatabase(formattedDate) {
    if (!formattedDate) return null; // Retorna null se a data for inválida ou não fornecida
    // Verifica se a string está no formato esperado
    const [day, month, year] = formattedDate.trim().split('/');
    if (!day || !month || !year) return null;
    // Retorna a data formatada para o banco
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  function formatGroup(selectedPatientGroup) {
    switch (selectedPatientGroup) {
      case '1':
        return 'Lactente';
      case '2':
        return 'Criança';
      case '3':
        return 'Adulto';
      case '4':
        return 'Idoso';
      case '5':
        return 'Gestante';
      case '6':
        return 'Lactante';
      default:
        return '';

    }
  }

  // const handleSubmit = async (values, { setSubmitting, resetForm }) => {
  //   console.log(`
  //     Altura: ${height} cm
  //     Peso: ${weight} kg
  //     Idade: ${age} anos
  //     Gênero: ${gender}
  //     Paciente Grupo: ${formatGroup(selectedPatientGroup)} - |${selectedPatientGroup}|
  //     Paciente Categoria: ${selectedPatientCategory}
  //     IMC : ${imc} kg/m2
  //     IMC Classificação: ${imcClassificacao}
  //     Atividade Física: ${frequencyOfActivity}
  //     Nível de Atividade Física: ${physicalActivityClassification} - [${physicalActivityLevel}]
  //     Frequância da Atividade Física: ${physicalActivityFrequency} - [${frequencyOfActivity}]
  //     Peso Teórico: ${pesoTeorico} kg
  //     Biotipo/Bernadinelli: ${classificacaoBernadinelli}
  //     Porcentagem Peso Teórico: ${porcentagemPT} % 
  //     Classificação Blackburn: ${classificacaoBlackburn} 
  //     Altura do Joelho: ${kneeHeight} cm
  //     Altura Estimada: ${alturaEstimada} cm
  //     Taxa do Metabolismo Basal TMB: ${tmb} kcal/dia
  //     Valor Energético Total VET: ${vet} kcal/dia
  //     Risco Circunferencia Cintura: ${waistClassification} - [${waist}]
  //     Circunferência Muscular do Braço CMB: ${cmb} cm
  //     Área Muscular do Braço AMB: ${amb} mm
  //     Área Muscular do Braço Corrigida AMBc: ${ambc} mm  | cb: ${armCircumference} mm | pct: ${tricepsSkinfold} mm
  //     Cálculo das Dobras Cutâneas: ${somaDobrasCutaneas} mm
  //     Percentual de Gordura: ${percentualGordura} %
  //     Equação PG usando: ${equationText}
  //     Área do Braço: ${ab} cm2
  //     Área Gordurosa do Braço: ${agb} cm2
  //     Classificação da Musculatura: ${classificacaoMusculatura}
  //     Data da Última Menstrução DUM: ${formatDate(dum)}
  //     Data Prevista do Parto: ${dppDate}
  //     Idade Gestacional: ${idadeGestacional} semanas
  //     Peso Pré Gestacional: ${ppgWeight} kg
  //     Ganho de peso Recomendado: ${recommendedWeightGain} kg
  //     IMC Gestacional: ${gestationalImc} kg/m2
  //     Peso da Massa Gorda: ${pesoMassaGorda} kg
  //     Peso da Massa Corporal: ${pesoMassaCorporal} kg
  //     Percentual Ideal de Gordura: ${percentualIdealGordura}
  //     Classificar Percentual de Gordura Interna GI: ${percentualGorduraInterna}
  //     Massa Corporal Magra MCM: ${massaCorporalMagra} 
  //     Total de àgua Corporal: ${totalAguaCorporal} lts
  //     Diagnóstico da Massa Muscular: ${diagnosticoMassaMuscular}
  //     Diagonóstico: ${diagnosis}
  //     `);

  //   try {
  //     // Atribuir valores calculados ao objeto 'values' a partir do estado
  //     values.gender = gender;
  //     values.age = age;
  //     values.height = height;
  //     values.weight = weight;
  //     values.imc = imc;
  //     values.imcClassificacao = imcClassificacao;
  //     values.physicalActivityLevel = physicalActivityLevel;

  //     // Exibir os dados que estão sendo enviados no console
  //     console.log('Dados do formulário:', values);

  //     const response = values.unwrap();
  //     console.log(`Resposta para envio: ${JSON.stringify(response)} `);


  //     // const response = await dispatch(createNewAnthropometry(values)).unwrap();


  //     setMessage("Antropometria criada com sucesso!");
  //     setShowModal(true);
  //     setTimeout(() => {
  //       setShowModal(false);
  //       resetForm();
  //       navigate('/home/anthropometry/get');
  //     }, 2000);
  //   } catch (error) {
  //     console.error("Erro ao enviar formulário:", error);
  //     const errorMsg = (error.response?.data?.message)
  //       ? error.response.data.message
  //       : 'Erro ao enviar formulário. Tente novamente mais tarde.';
  //     setMessage(errorMsg);
  //     setShowModal(true);
  //     setTimeout(() => {
  //       setShowModal(false);
  //     }, 2500);
  //   }
  //   setSubmitting(false);
  // };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log(`
      Altura: ${height} cm
      Peso: ${weight} kg
      Idade: ${age} anos
      Idade - anos: ${Number.parseInt(patientYear)} anos
      Idade - meses: ${Number.parseInt(patientMonth)} meses
      Gênero: ${gender}
      Paciente Grupo: ${formatGroup(selectedPatientGroup)} - |${selectedPatientGroup}|
      Paciente Categoria: ${selectedPatientCategory}
      IMC | BMI : ${imc} | ${bmi} kg/m2
      IMC Classificação: ${imcClassificacao}
      Atividade Física: ${frequencyOfActivity}
      Nível de Atividade Física: ${physicalActivityClassification} - [${physicalActivityLevel}]
      Frequência da Atividade Física: ${physicalActivityFrequency} - [${frequencyOfActivity}]
      Peso Teórico: ${pesoTeorico} kg
      Biotipo/Bernadinelli: ${classificacaoBernadinelli}
      Porcentagem Peso Teórico: ${porcentagemPT} % 
      Classificação Blackburn: ${classificacaoBlackburn}
      Classificação OMS (George Bray): ${classificacaoBray} aqui deve ser a classificação OMS
      Altura do Joelho: ${kneeHeight} cm
      Altura Estimada: ${alturaEstimada} cm
      Taxa do Metabolismo Basal TMB: ${tmb} kcal/dia
      Valor Energético Total VET: ${vet} kcal/dia
      Risco Circunferência Cintura: ${waistClassification} - [${waist}]
      Circunferência Muscular do Braço CMB: ${cmb} cm
      Circunferência do Tornozelo = ${ankleCircumference} cm
      Circunferência Abdominal: ${abdominalCircumference} cm
      Área Muscular do Braço AMB: ${amb} mm
      Área Muscular do Braço Corrigida AMBc: ${ambc} mm  | cb: ${armCircumference} mm | pct: ${tricepsSkinfold} mm
      Dobra Supraespinhal: ${supraespinalSkinfold} mm
      Cálculo das Dobras Cutâneas: ${somaDobrasCutaneas} mm
      Percentual de Gordura: ${percentualGordura} %
      Equação PG usando: ${equationText}
      Área do Braço: ${ab} cm2
      Área Gordurosa do Braço: ${agb} cm2
      Classificação da Musculatura: ${classificacaoMusculatura}
      Data da Última Menstruação DUM: ${formatDate(dum)}
      Data Prevista do Parto: ${dppDate}
      Idade Gestacional: ${idadeGestacional} semanas
      Peso Pré Gestacional: ${ppgWeight} kg
      Ganho de peso Recomendado: ${recommendedWeightGain} kg
      IMC Gestacional: ${gestationalImc} kg/m2
      Peso da Massa Gorda: ${pesoMassaGorda} kg
      Peso da Massa Corporal: ${pesoMassaCorporal} kg
      Percentual Ideal de Gordura: ${percentualIdealGordura}
      Classificar Percentual de Gordura Interna GI: ${percentualGorduraInterna}
      Massa Corporal Magra MCM: ${massaCorporalMagra} 
      Total de água Corporal: ${totalAguaCorporal} lts
      Diagnóstico da Massa Muscular: ${diagnosticoMassaMuscular}
      Diagnóstico: ${diagnosis}
    `);

    try {
      // Atribuir valores calculados ao objeto 'values' conforme o mapeamento
      // values = {
      //   ...values,
      //   height,
      //   ab: ab,
      //   abdominal_skinfold: Number.parseFloat(abdominalSkinfold),
      //   activity_factor_code: physicalActivityFrequency,
      //   agb: Number.parseFloat(agb),
      //   age_years: age,
      //   ambc: Number.parseFloat(ambc),
      //   ankle_circumference: Number.parseFloat(ankleCircumference),

      //   current_weight: weight,
      //   patient_group_id: selectedPatientGroup,
      //   category_id: selectedPatientCategory,
      //   bmi_classification_code: imcClassificacao,
      //   physical_activity_frequency: frequencyOfActivity,
      //   physical_activity_level: physicalActivityLevel,

      //   lean_weight_type: pesoTeorico,
      //   pg_classification_code: classificacaoBernadinelli,
      //   formula_pt_code: porcentagemPT,
      //   bray_classification: classificacaoBlackburn,
      //   knee_height: Number.parseFloat(kneeHeight),

      //   estimated_height: alturaEstimada,
      //   formula_vet_code: tmb,
      //   tmb: tmb,
      //   vet: vet,
      //   waist_circumference: waist,
      //   mcm: cmb,
      //   muscle_mass_weight: amb,
      //   arm_circumference: armCircumference,
      //   triceps_skinfold: tricepsSkinfold,
      //   sum_skinfolds: somaDobrasCutaneas,
      //   body_fat_percentage: percentualGordura,
      //   fat_calculation_equation: equationText,
      //   radial_diameter: ab,
      //   forearm_circumference: agb,

      //   muscle_mass_classification: classificacaoMusculatura,
      //   last_period_date: dum,
      //   ddp_date: dppDate,
      //   gestational_age: idadeGestacional,
      //   ppg_weight: ppgWeight,
      //   recommended_weight_gain: recommendedWeightGain,
      //   gestational_imc: gestationalImc,
      //   fat_mass_weight: pesoMassaGorda,
      //   ideal_fat_percentage: percentualIdealGordura,
      //   fat_mass_internal_percentual: percentualGorduraInterna,
      //   lean_body_mass_mcm: massaCorporalMagra,
      //   total_body_water: totalAguaCorporal,
      //   muscle_mass_diagnosis: diagnosticoMassaMuscular,
      //   diagnosis
      // };
      // console.log('Dados do formulário:', values);

      const payload = {
        ...values,
        category_id: 1,
        estimated_current_weight: 0,
        reference_estimated_weight: 0,
        lean_weight_type: "mcm",
        activity_factor_code: 1,
        height: Number.parseFloat(height),
        weight: Number.parseFloat(weight),
        age: Number.parseFloat(age),
        age_years: age,
        age_months: patientMonth,
        bmi: Number.parseFloat(bmi),
        theoretical_weight: Number.parseFloat(pesoTeorico),
        estimated_height: Number.parseFloat(alturaEstimada),
        tmb: Number.parseFloat(tmb),
        vet: Number.parseFloat(vet),
        waist: Number.parseFloat(waist),
        waist_classification: waistClassification,
        arm_circumference: Number.parseFloat(cmb),
        arm_muscle_area: Number.parseFloat(amb),
        arm_muscle_area_adj: Number.parseFloat(ambc),
        somaDobrasCutaneas: Number.parseFloat(somaDobrasCutaneas),
        percentualGordura: Number.parseFloat(percentualGordura),
        arm_area: Number.parseFloat(ab),
        arm_fat_area: Number.parseFloat(agb),
        kneeHeight: Number.parseFloat(kneeHeight),
        armCircumference: Number.parseFloat(armCircumference),
        ankleCircumference: Number.parseFloat(ankleCircumference),
        abdominalCircumference: Number.parseFloat(abdominalCircumference),
        chestCircumference: Number.parseFloat(chestCircumference),
        forearmCircumference: Number.parseFloat(forearmCircumference),
        glutealMaxCircumference: Number.parseFloat(glutealMaxCircumference),
        headCircumference: Number.parseFloat(headCircumference),
        hipCircumference: Number.parseFloat(hipCircumference),
        thighMaxCircumference: Number.parseFloat(thighMaxCircumference),
        calfCircumference: Number.parseFloat(calfCircumference),
        tricepsSkinfold: Number.parseFloat(tricepsSkinfold),
        suprailiacSkinfold: Number.parseFloat(suprailiacSkinfold),
        subscapularSkinfold: Number.parseFloat(subscapularSkinfold),
        supraespinalSkinfold: Number.parseFloat(supraespinalSkinfold),
        chestSkinfold: Number.parseFloat(chestSkinfold),
        bicepsSkinfold: Number.parseFloat(bicepsSkinfold),
        thighSkinfold: Number.parseFloat(thighSkinfold),
        calfSkinfold: Number.parseFloat(calfSkinfold),
        abdominalSkinfold: Number.parseFloat(abdominalSkinfold),
        axillarySkinfold: Number.parseFloat(axillarySkinfold),
        massaCorporalMagra: Number.parseFloat(massaCorporalMagra),
        totalAguaCorporal: Number.parseFloat(totalAguaCorporal),
        pesoMassaGorda: Number.parseFloat(pesoMassaGorda),
        gestationalImc: Number.parseFloat(gestationalImc),
        waistCircumference: Number.parseFloat(waistCircumference),
        recommendedWeightGain: Number.parseFloat(recommendedWeightGain),
        formula_vet_code: selectedFatPercentageEquation,
        gestational_age: Number.parseInt(idadeGestacional),
        ddp_date: convertDateForDatabase(dppDate),
        ppg_weight: Number.parseFloat(ppgWeight),
        last_period_date: dum,
        mcm: Number.parseFloat(massaCorporalMagra),
        lean_body_mass_mcm: Number.parseFloat(massaCorporalMagra),
        fat_mass_internal_percentual: percentualGorduraInterna,
        gestational_imc: Number.parseFloat(gestationalImc),
        recomended_weight_gain: Number.parseFloat(recommendedWeightGain),
        muscle_mass_classification: classificacaoMusculatura,
        muscle_mass_diagnosis: diagnosticoMassaMuscular,
        body_fat_percentage: Number.parseFloat(percentualGordura),
        fat_mass_weight: Number.parseFloat(pesoMassaGorda),
        muscle_mass_weight: Number.parseFloat(pesoMassaCorporal),
        total_body_water: Number.parseFloat(totalAguaCorporal),
        ideal_fat_percentage: percentualIdealGordura,
        bmi_classification_code: imcClassificacao,
        pg_classification_code: classificacaoBernadinelli,
        bray_classification: classificacaoBray,
        blackburn_classification: classificacaoBlackburn,
        fat_calculate_equation: equationText,
      };

      console.log('Payload convertido para float:', payload);
      // Envie payload ao backend

      // Enviar os dados ao backend (substitua pelo seu dispatch real)
      const response = await dispatch(createNewAnthropometry(payload)).unwrap();

      setMessage("Antropometria criada com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        resetForm();
        navigate('/home/anthropometry/get');
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      const errorMsg = error.response?.data?.message || 'Erro ao enviar formulário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
    }
    setSubmitting(false);
  };


  return (
    <>
      <div>
        <h3>Nova Antropometria</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          onKeyDown={handleKeyDown}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form className='anthropometryForm'>
              <div className="anthropometryMain">
                <div className="anthropometry-colA">
                  <div className="anthropometry-form-group">
                    <label htmlFor="patient_id">Paciente:</label>
                    <Select
                      name="patient_id"
                      options={patients.map(patient => ({
                        value: patient.person_id,
                        label: patient.name,
                      }))}
                      onChange={option => handlePatientChange(option, setFieldValue)}
                      classNamePrefix="custom-select"
                      placeholder="Selecione o paciente"
                    />
                    <ErrorMessage name="patient_id" component="div" className="error-message" />
                  </div>

                  <div className="anthropometry-form-group">
                    <label htmlFor="nutritionist_id">Nutricionista:</label>
                    <Select
                      name="nutritionist_id"
                      options={selectOptions(professionals, 'name')}
                      onChange={option => setFieldValue('nutritionist_id', option ? option.value : '')}
                      classNamePrefix="custom-select"
                      placeholder="Selecione o nutricionista"
                    />
                    <ErrorMessage name="nutritionist_id" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="patient_group_id">Grupo do Paciente:</label>
                    {/* <Field
                      as="select"
                      name="patient_group_id"
                      id="patient_group_id"
                      onChange={(e) => {
                        const selectedGroup = e.target.value;
                        setFieldValue('patient_group_id', selectedGroup);  // Atualiza o valor no form
                        setSelectedPatientGroup(selectedGroup);  // Atualiza o estado do grupo do paciente
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Lactente</option>
                      <option value="2">Criança</option>
                      <option value="3">Adulto</option>
                      <option value="4">Idoso</option>
                      <option value="5">Gestante</option>
                      <option value="6">Lactante</option>
                    </Field> */}
                    < Field
                      as="select"
                      name="patient_group_id"
                      id="patient_group_id"
                      onChange={(e) => {
                        const selectedGroup = e.target.value;
                        setFieldValue('patient_group_id', selectedGroup);
                        setSelectedPatientGroup(selectedGroup);

                        // Verifica se o paciente é criança e habilita o campo de z-score
                        if (selectedGroup === '2') {
                          setShowZScoreField(true);
                        } else {
                          setShowZScoreField(false);  // Oculta o campo para outros grupos
                        }
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Lactente</option>
                      <option value="2">Criança</option>  {/* Grupo Criança */}
                      <option value="3">Adulto</option>
                      <option value="4">Idoso</option>
                      <option value="5">Gestante</option>
                      <option value="6">Lactante</option>
                    </Field >
                    <ErrorMessage name="patient_group_id" component="div" className="error-message" />
                    {erroGrupoGestante && <div className="error-message">{erroGrupoGestante}</div>}
                  </div >

                  {showZScoreField && (
                    <div className="anthropometry-form-group">
                      <label htmlFor="zscore">z-Score</label>
                      <Field
                        type="number"
                        name="zscore"
                        id="zscore"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'zscore')}
                        onChange={(e) => {
                          setFieldValue('zscore', e.target.value);
                          setImcZscore(e.target.value); // Atualiza o z-Score da criança
                        }}
                      />
                      <ErrorMessage name="zscore" component="div" className="error-message" />
                    </div>
                  )}


                  {/* <div className="anthropometry-form-group">
                    <label htmlFor="category_id">Categoria do Paciente:</label>
                    <Field
                      as="select"
                      name="category_id"
                      id="category_id"
                      onChange={(e) => {
                        const selectedCategory = e.target.value;
                        setFieldValue('category_id', selectedCategory);  // Atualiza o valor no form
                        setSelectedPatientCategory(selectedCategory);  // Atualiza o estado do grupo do paciente
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Categoria 1</option>
                      <option value="2">Categoria 2</option>
                      <option value="3">Categoria 3</option>
                      <option value="4">Categoria 4</option>
                      <option value="5">Categoria 5</option>
                      <option value="6">Categoria 6</option>
                    </Field>
                    <ErrorMessage name="category_id" component="div" className="error-message" />
                  </div> */}

                  <div className="anthropometry-form-group">
                    <label htmlFor="measurement_date">Data da Medição:</label>
                    <Field type="date" name="measurement_date" id="measurement_date" />
                    <ErrorMessage name="measurement_date" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="height">Altura (cm):</label>
                    <Field
                      type="number"
                      name="height"
                      id="height"
                      onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'height')}
                      onChange={(e) => {
                        setFieldValue('height', e.target.value);
                        setHeight(e.target.value); // Atualiza o estado de altura
                      }}
                    />
                    <ErrorMessage name="height" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="current_weight">Peso Atual (kg):</label>
                    <Field
                      type="number"
                      name="current_weight"
                      id="current_weight"
                      onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'current_weight')}
                      onChange={(e) => {
                        setFieldValue('current_weight', e.target.value);
                        setWeight(e.target.value); // Atualiza o estado de peso
                      }}
                    />
                    <ErrorMessage name="current_weight" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="desired_weight">Peso Desejado (kg):</label>
                    <Field type="number" name="desired_weight" id="desired_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'desired_weight')} />
                    <ErrorMessage name="desired_weight" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="usual_weight">Peso Habitual (kg):</label>
                    <Field type="number" name="usual_weight" id="usual_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'usual_weight')} />
                    <ErrorMessage name="usual_weight" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="overweight_duration">Tempo de Sobrepeso: (ano/meses)</label>
                    <Field type="number" name="overweight_duration" id="overweight_duration" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'overwight_duration')} />
                    <ErrorMessage name="overweight_duration" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="ppr_time">Perda de Peso Recente - PPR:</label>
                    <Field type="number" name="ppr_time" id="ppr_time" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'ppr_time')} />
                    <ErrorMessage name="ppr_time" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="physical_activity_frequency">Atividade Física:</label>
                    <Field
                      as="select"
                      name="physical_activity_frequency"
                      id="physical_activity_frequency"
                      onChange={(e) => {
                        setFieldValue('physical_activity_frequency', e.target.value);
                        setFrequencyOfActivity(e.target.value); // Atualiza o estado da physicalActivity
                      }}>
                      <option value="">Selecione</option>
                      <option value="unable">Nenhuma</option>
                      <option value="sedentary">Sedentario</option>
                      <option value="occasional">Ocasional</option>
                      <option value="frequent">Frequente</option>
                      <option value="regular">Regular</option>
                    </Field>
                    <ErrorMessage name="physical_activity_frequency" component="div" className="error-message" />
                  </div>
                  <div className="anthropometry-form-group">
                    <label htmlFor="physical_activity_level">Nível da Atividade Física:</label>
                    <Field
                      as="select"
                      name="physical_activity_level"
                      id="physical_activity_level"
                      onChange={(e) => {
                        setFieldValue('physical_activity_level', e.target.value);
                        setPhysicalActivityLevel(e.target.value); // Atualiza o estado da physicalActivityLevel
                      }}>
                      <option value="">Selecione</option>
                      <option value="light">Leve</option>
                      <option value="moderate">Moderada</option>
                      <option value="intense">Intensa</option>
                    </Field>
                    <ErrorMessage name="physical_activity_level" component="div" className="error-message" />
                  </div>
                  {/* <div className="anthropometry-form-group">
                    <label htmlFor="bmi_classification_code">Classificação IMC:</label>
                    <Field as="select" name="bmi_classification_code" id="bmi_classification_code">
                      <option value="">Selecione</option>
                      <option value="underweight">Abaixo do Peso</option>
                      <option value="normal">Normal</option>
                      <option value="overweight">Sobrepeso</option>
                      <option value="obese">Obesidade</option>
                    </Field>
                    <ErrorMessage name="bmi_classification_code" component="div" className="error-message" />
                  </div> */}

                  {/* Verifica se o paciente é do gênero feminino */}
                  {
                    selectedPatientGender === 'female' && (
                      <>
                        {/* Exibe o campo de DUM para todas as pacientes do gênero feminino */}
                        <div className="anthropometry-form-group">
                          <label htmlFor="last_period_date">Data da Última Menstruação - DUM:</label>
                          <Field
                            type="date"
                            name="last_period_date"
                            id="last_period_date"
                            onChange={(e) => {
                              const dumValue = e.target.value;
                              setFieldValue('last_period_date', dumValue);
                              setDum(dumValue);
                              handleGestanteCalculations(dumValue); // Calcula IG e DPP automaticamente
                            }}
                          />
                          <ErrorMessage name="last_period_date" component="div" className="error-message" />
                        </div>

                        {/* Verifica se o grupo do paciente é "Gestante" */}
                        {selectedPatientGroup === '5' && (
                          <>
                            {/* Campo calculado para Idade Gestacional (não editável) */}
                            <div className="anthropometry-form-group">
                              <label htmlFor="gestational_age">Idade Gestacional - IG (semanas):</label>
                              <Field
                                type="text"
                                name="gestational_age"
                                id="gestational_age"
                                value={idadeGestacional || ''} // Valor calculado
                                disabled
                              />
                              <ErrorMessage name="gestational_age" component="div" className="error-message" />
                            </div>

                            {/* Campo calculado para Data Prevista do Parto (não editável) */}
                            <div className="anthropometry-form-group">
                              <label htmlFor="dpp_date">Data Prevista do Parto - DPP:</label>
                              <Field
                                type="text"
                                name="dpp_date"
                                id="dpp_date"
                                value={dppDate || ''} // Valor calculado
                                disabled
                              />
                              <ErrorMessage name="dpp_date" component="div" className="error-message" />
                            </div>

                            {/* Campo de Peso Pré Gestacional (input manual) */}
                            <div className="anthropometry-form-group">
                              <label htmlFor="ppg_weight">Peso Pré Gestacional - PPG (kg):</label>
                              <Field
                                type="number"
                                name="ppg_weight"
                                id="ppg_weight"
                                onChange={(e) => {
                                  setFieldValue('ppg_weight', e.target.value);
                                  setPpgWeight(e.target.value); // Atualiza o estado de ppgWeight
                                }}
                              />
                              <ErrorMessage name="ppg_weight" component="div" className="error-message" />
                            </div>

                            {/* Campo de Ganho de Peso Recomendado */}
                            <div className="anthropometry-form-group">
                              <label htmlFor="recommended_weight_gain">Ganho de Peso Recomendado - GPR (kg):</label>
                              <Field
                                type="number"
                                name="recommended_weight_gain"
                                id="recommended_weight_gain"
                                onChange={(e) => {
                                  setFieldValue('recommended_weight_gain', e.target.value);
                                  setRecommendedWeightGain(e.target.value); // Atualiza o estado de GPR
                                }}
                              />
                              <ErrorMessage name="recommended_weight_gain" component="div" className="error-message" />
                            </div>

                            {/* IMC Gestacional (calculado automaticamente) */}
                            <div className="anthropometry-form-group">
                              <label htmlFor="gestational_imc">IMC - Gestacional:</label>
                              <Field
                                type="text"
                                name="gestational_imc"
                                id="gestational_imc"
                                value={gestationalImc || ''} // IMC calculado
                                disabled
                              />
                              <ErrorMessage name="gestational_imc" component="div" className="error-message" />
                            </div>
                          </>
                        )}
                      </>
                    )
                  }
                  {/* <Escalometro biotipoIndex={classificarBiotipo} /> */}
                </div >

                <div className="anthropometry-colB">
                  <Accordion title={"Circunferências"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="head_circumference">Circunferência da Cabeça (cm):</label>
                      <Field
                        type="number"
                        name="head_circumference"
                        id="head_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'head_circumference')}
                        onChange={(e) => {
                          setFieldValue('head_circumference', e.target.value);
                          setHeadCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="head_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="arm_circumference">Circunferência do Braço (cm):</label>
                      <Field
                        type="number"
                        name="arm_circumference"
                        id="arm_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'arm_circumference')}
                        onChange={(e) => {
                          setFieldValue('arm_circumference', e.target.value);
                          setArmCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="arm_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="waist_circumference">Circunferência da Cintura (cm):</label>
                      <Field
                        type="number"
                        name="waist_circumference"
                        id="waist_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'waist_circumference')}
                        onChange={(e) => {
                          setFieldValue('waist_circumference', e.target.value);
                          setWaistCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="waist_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="chest_circumference">Circunferência do Tórax (cm):</label>
                      <Field
                        type="number"
                        name="chest_circumference"
                        id="chest_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'chest_circumference')}
                        onChange={(e) => {
                          setFieldValue('chest_circumference', e.target.value);
                          setChestCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="chest_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="hip_circumference">Circunferência do Quadril (cm):</label>
                      <Field
                        type="number"
                        name="hip_circumference"
                        id="hip_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'hip_circumference')}
                        onChange={(e) => {
                          setFieldValue('hip_circumference', e.target.value);
                          setHipCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="hip_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="gluteal_max_circumference">Circunferência Máxima do Glúteo (cm):</label>
                      <Field
                        type="number"
                        name="gluteal_max_circumference"
                        id="gluteal_max_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'gluteal_max_circumference')}
                        onChange={(e) => {
                          setFieldValue('gluteal_max_circumference', e.target.value);
                          setGlutealMaxCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="gluteal_max_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="thigh_max_circumference">Circunferência Máxima da Coxa (cm):</label>
                      <Field
                        type="number"
                        name="thigh_max_circumference"
                        id="thigh_max_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'thigh_max_circumference')}
                        onChange={(e) => {
                          setFieldValue('thigh_max_circumference', e.target.value);
                          setThighMaxCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="thigh_max_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="forearm_circumference">Circunferência Máxima do Antebraço (cm):</label>
                      <Field
                        type="number"
                        name="forearm_circumference"
                        id="forearm_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'forearm_circumference')}
                        onChange={(e) => {
                          setFieldValue('forearm_circumference', e.target.value);
                          setForearmCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="forearm_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="wrist_circumference">Circunferência do Pulso (cm):</label>
                      <Field
                        type="number"
                        name="wrist_circumference"
                        id="wrist_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'wrist_circumference')}
                        onChange={(e) => {
                          setFieldValue('wrist_circumference', e.target.value);
                          setWristCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="wrist_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="calf_circumference">Circunferência da Panturrilha (cm):</label>
                      <Field
                        type="number"
                        name="calf_circumference"
                        id="calf_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'calf_circumference')}
                        onChange={(e) => {
                          setFieldValue('calf_circumference', e.target.value);
                          setCalfCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="calf_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="ankle_circumference">Circunferência do Tornozelo (cm):</label>
                      <Field
                        type="number"
                        name="ankle_circumference"
                        id="ankle_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'ankle_circumference')}
                        onChange={(e) => {
                          setFieldValue('ankle_circumference', e.target.value);
                          setAnkleCircumference(e.target.value); // Atualiza o estado de peso
                        }}
                      />
                      <ErrorMessage name="ankle_circumference" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="abdominal_circumference">Circunferência Abdominal (cm):</label>
                      <Field
                        type="number"
                        name="abdominal_circumference"
                        id="abdominal_circumference"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'abdominal_circumference')}
                        onChange={(e) => {
                          setFieldValue('abdominal_circumference', e.target.value);
                          setAbdominalCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="abdominal_circumference" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <ErrorMessage name="arm_circumference" component="div" className="error-message" />
                  <Accordion title={"Braços"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_arm">Braço Esquerdo (cm):</label>
                      <Field
                        type="number"
                        name="left_arm"
                        id="left_arm"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_arm')}
                        onChange={(e) => {
                          setFieldValue('left_arm', e.target.value);
                          setLeftArmCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="left_arm" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_arm">Braço Direito (cm):</label>
                      <Field
                        type="number"
                        name="right_arm"
                        id="right_arm"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_arm')}
                        onChange={(e) => {
                          setFieldValue('right_arm', e.target.value);
                          setRightArmCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="right_arm" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_forearm">Antebraço Esquerdo (cm):</label>
                      <Field
                        type="number"
                        name="left_forearm"
                        id="left_forearm"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_forearm')}
                        onChange={(e) => {
                          setFieldValue('left_forearm', e.target.value);
                          setLeftForeArmCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="left_forearm" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_forearm">Antebraço Direito (cm):</label>
                      <Field
                        type="number"
                        name="right_forearm"
                        id="right_forearm"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_forearm')}
                        onChange={(e) => {
                          setFieldValue('right_forearm', e.target.value);
                          setRightForeArmCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="right_forearm" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_hand">Mão Esquerda (cm):</label>
                      <Field
                        type="number"
                        name="left_hand"
                        id="left_hand"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_hand')}
                        onChange={(e) => {
                          setFieldValue('left_hand', e.target.value);
                          setLeftHandCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="left_hand" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_hand">Mão Direita (cm):</label>
                      <Field
                        type="number"
                        name="right_hand"
                        id="right_hand"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_hand')}
                        onChange={(e) => {
                          setFieldValue('right_hand', e.target.value);
                          setRightHandCircumference(e.target.value);
                        }}
                      />
                      <ErrorMessage name="right_hand" component="div" className="error-message" />
                    </div>
                  </Accordion>

                  <Accordion title={"Pernas - Pés"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_leg">Perna Esquerda (cm):</label>
                      <Field type="number" name="left_leg" id="left_leg" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_leg')} />
                      <ErrorMessage name="left_leg" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_leg">Perna Direita (cm):</label>
                      <Field type="number" name="right_leg" id="right_leg" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_leg')} />
                      <ErrorMessage name="right_leg" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_lower_leg">Perna Inferior Esquerda (cm):</label>
                      <Field type="number" name="left_lower_leg" id="left_lower_leg" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_lower_leg')} />
                      <ErrorMessage name="left_lower_leg" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_lower_leg">Perna Inferior Direita (cm):</label>
                      <Field type="number" name="right_lower_leg" id="right_lower_leg" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_lower_leg')} />
                      <ErrorMessage name="right_lower_leg" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="right_foot">Pé Esquerdo (cm):</label>
                      <Field type="number" name="right_foot" id="right_foot" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'right_foot')} />
                      <ErrorMessage name="right_foot" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="left_foot">Pé Direito (cm):</label>
                      <Field type="number" name="left_foot" id="left_foot" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'left_foot')} />
                      <ErrorMessage name="left_foot" component="div" className="error-message" />
                    </div>
                  </Accordion>

                  <Accordion title={"Diâmetros e Alturas Osseas"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="radial_diameter">Diâmetro do Radial (cm):</label>
                      <Field type="number" name="radial_diameter" id="radial_diameter" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'radial_diameter')} />
                      <ErrorMessage name="radial_diameter" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="femur_diameter">Diâmetro do Fêmur (cm):</label>
                      <Field type="number" name="femur_diameter" id="femur_diameter" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'femur_diameter')} />
                      <ErrorMessage name="femur_diameter" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="knee_height">Altura do Joelho (cm):</label>
                      <Field
                        type="number"
                        name="knee_height"
                        id="knee_height"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'knee_height')}
                        onChange={(e) => {
                          setFieldValue('knee_height', e.target.value);
                          setKneeHeight(e.target.value); // Atualiza o estado de altura do joelho
                        }} />
                      <ErrorMessage name="knee_height" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="humeral_diameter">Diâmetro do Úmero (cm):</label>
                      <Field type="number" name="humeral_diameter" id="humeral_diameter" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'humeral_diameter')} />
                      <ErrorMessage name="humeral_diameter" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  <ErrorMessage name="knee_height" component="div" className="error-message" />
                  <Accordion title={"Dobras Cutâneas"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="suprailiac_skinfold">Dobra Suprailíaca (mm):</label>
                      <Field type="number" name="suprailiac_skinfold" id="suprailiac_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'suprailiac_skinfold')} />
                      <ErrorMessage name="suprailiac_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="subscapular_skinfold">Dobra Subescapular (mm):</label>
                      <Field
                        type="number"
                        name="subscapular_skinfold"
                        id="subscapular_skinfold"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'subscapular_skinfold')}
                        onChange={(e) => {
                          setFieldValue('subscapular_skinfold', e.target.value);
                          setSubscapularSkinfold(e.target.value); // Atualiza o estado do tricpsSkinfold
                        }}
                      />
                      <ErrorMessage name="subscapular_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="supraespinal_skinfold">Dobra Supraespinhal (mm):</label>
                      <Field
                        type="number"
                        name="supraespinal_skinfold"
                        id="supraespinal_skinfold"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'supraespinal_skinfold')}
                        onChange={(e) => {
                          setFieldValue('supraespinal_skinfold', e.target.value);
                          setSupraespinalSkinfold(e.target.value); // Atualiza o estado do supraespinalSkinfold
                        }}
                      />
                      <ErrorMessage name="supraespinal_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="chest_skinfold">Dobra Peitoral (mm):</label>
                      <Field
                        type="number"
                        name="chest_skinfold"
                        id="chest_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'chest_skinfold')}
                        onChange={(e) => {
                          setFieldValue('chest_skinfold', e.target.value);
                          setChestSkinfold(e.target.value); // Atualiza o estado do chestSkinfold
                        }}
                      />
                      <ErrorMessage name="chest_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="triceps_skinfold">Dobra Trícepes (mm):</label>
                      <Field
                        type="number"
                        name="triceps_skinfold"
                        id="triceps_skinfold"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'triceps_skinfold')}
                        onChange={(e) => {
                          setFieldValue('triceps_skinfold', e.target.value);
                          setTricepsSkinfold(e.target.value); // Atualiza o estado do tricpsSkinfold
                        }}
                      />
                      <ErrorMessage name="triceps_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="biceps_skinfold">Dobra Bícepes (mm):</label>
                      <Field
                        type="number"
                        name="biceps_skinfold"
                        id="biceps_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'biceps_skinfold')}
                        onChange={(e) => {
                          setFieldValue('biceps_skinfold', e.target.value);
                          setBicepsSkinfold(e.target.value); // Atualiza o estado do tricpsSkinfold
                        }}
                      />
                      <ErrorMessage name="biceps_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="thigh_skinfold">Dobra da Coxa (mm):</label>
                      <Field
                        type="number"
                        name="thigh_skinfold"
                        id="thigh_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'thigh_skinfold')}
                        onChange={(e) => {
                          setFieldValue('thigh_skinfold', e.target.value);
                          setThighSkinfold(e.target.value); // Atualiza o estado do tricpsSkinfold
                        }}
                      />
                      <ErrorMessage name="thigh_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="calf_skinfold">Dobra da Panturrilha (mm):</label>
                      <Field
                        type="number"
                        name="calf_skinfold"
                        id="calf_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'calf_skinfold')}
                        onChange={(e) => {
                          setFieldValue('calf_skinfold', e.target.value);
                          setCalfSkinfold(e.target.value); // Atualiza o estado do tricpsSkinfold
                        }}
                      />
                      <ErrorMessage name="calf_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="abdominal_skinfold">Dobra Abdominal (mm):</label>
                      <Field
                        type="number"
                        name="abdominal_skinfold"
                        id="abdominal_skinfold" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'abdominal_skinfold')}
                        onChange={(e) => {
                          setFieldValue('abdominal_skinfold', e.target.value);
                          setAbdominalSkinfold(e.target.value); // Atualiza o estado do abdominalSkinfold
                        }}
                      />
                      <ErrorMessage name="abdominal_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="axillary_skinfold">Dobra Axilar (mm):</label>
                      <Field
                        type="number"
                        name="axillary_skinfold"
                        id="axillary_skinfold"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'axillary_skinfold')}
                        onChange={(e) => {
                          setFieldValue('axillary_skinfold', e.target.value);
                          setAxillarySkinfold(e.target.value); // Atualiza o estado do tricpsSkinfold
                        }}
                      />
                      <ErrorMessage name="axillary_skinfold" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="fat_calculate_equation">Equação do Cálculo de Gordura:</label>
                      <Field
                        as="select"
                        name="fat_calculate_equation"
                        id="fat_calculate_equation"
                        onChange={(e) => {
                          const selectedFatEquation = e.target.value;
                          const selectedText = e.target.options[e.target.selectedIndex].text;
                          setEquationText(selectedText);
                          setFieldValue('fat_calculate_equation', selectedFatEquation);  // Atualiza o valor no form
                          setSelectedFatPercentageEquation(selectedFatEquation); // Atualiza a Equação a a ser utilizada
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1">3 Dobras (18 - 60 anos) - Jackson e Pollock - 3 dobras cutâneas (1978)</option>
                        <option value="2">7 Dobras (18 - 60 anos) - Jackson e Pollock - 7 dobras cutâneas (1978)</option>
                        <option value="3">4 Dobras (18 - 60 anos) - Petrosky - (1995)</option>
                        <option value="4">4 Dobras (Homens 17 - 72 anos e Mulheres 16 - 68 anos) - Durnin e Whomerley (1974)</option>
                      </Field>
                      <ErrorMessage name="fat_calculate_equation" component="div" className="error-message" />
                    </div>
                    {/* <div className="anthropometry-form-group">
                      <label htmlFor="measurement_type">Tipo de Medição:</label>
                      <Field
                        type="text"
                        name="measurement_type"
                        id="measurement_type" onKeyDown={handleKeyDown}

                      />
                      <ErrorMessage name="measurement_type" component="div" className="error-message" />
                    </div> */}
                  </Accordion>
                  <ErrorMessage name="subscapular_skinfold" component="div" className="error-message" />
                  <ErrorMessage name="chest_skinfold" component="div" className="error-message" />
                  <ErrorMessage name="triceps_skinfold" component="div" className="error-message" />
                  <ErrorMessage name="biceps_skinfold" component="div" className="error-message" />
                  <ErrorMessage name="fat_calculate_equation" component="div" className="error-message" />

                  <Accordion title={"Classificações e Diagnóstico"} className="accordion">
                    {/* <div className="anthropometry-form-group">
                      <label htmlFor="estimated_height">Altura Estimada (cm):</label>
                      <Field
                        type="number"
                        name="estimated_height"
                        id="estimated_height"
                        onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'estimated_height')}

                      />
                      <ErrorMessage name="estimated_height" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="estimated_weight">Peso Estimado (kg):</label>
                      <Field type="number" name="estimated_weight" id="estimated_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'estimated_weight')} />
                      <ErrorMessage name="estimated_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="estimated_current_weight">Peso Atual Estimado (kg):</label>
                      <Field type="number" name="estimated_current_weight" id="estimated_current_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'estimated_current_weight')} />
                      <ErrorMessage name="estimated_current_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="reference_estimated_weight">Peso de Referência Estimado (kg):</label>
                      <Field type="number" name="reference_estimated_weight" id="reference_estimated_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'reference_estimated_weight')} />
                      <ErrorMessage name="reference_estimated_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="lean_weight_type">Tipo de Peso Magro:</label>
                      <Field type="number" name="lean_weight_type" id="lean_weight_type" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'lean_weight_type')} />
                      <ErrorMessage name="lean_weight_type" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="fat_mass_weight">Peso da Massa Gorda (kg):</label>
                      <Field type="number" name="fat_mass_weight" id="fat_mass_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'fat_mass_weight')} />
                      <ErrorMessage name="fat_mass_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="muscle_mass_weight">Peso da Massa Corporal (kg):</label>
                      <Field type="number" name="muscle_mass_weight" id="muscle_mass_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'muscle_mass_weight')} />
                      <ErrorMessage name="muscle_mass_weight" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="body_fat_percentage">Percentual de Gordura Corporal (%):</label>
                      <Field type="number" name="body_fat_percentage" id="body_fat_percentage" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'body_fat_percentage')} />
                      <ErrorMessage name="body_fat_percentage" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="ideal_fat_percentage">Percentual Ideal de Gordura (%):</label>
                      <Field type="number" name="ideal_fat_percentage" id="ideal_fat_percentage" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'ideal_fat_percentage')} />
                      <ErrorMessage name="ideal_fat_percentage" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="gi_percentage_type">Tipo de Percentual GI (%):</label>
                      <Field type="number" name="gi_percentage_type" id="gi_percentage_type" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'gi_percentage_type')} />
                      <ErrorMessage name="gi_percentage_type" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="total_body_water">Total Corporal de Água  (ml):</label>
                      <Field type="number" name="total_body_water" id="total_body_water" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'total_body_water')} />
                      <ErrorMessage name="total_body_water" component="div" className="error-message" />
                    </div> 

                    <div className="anthropometry-form-group">
                      <label htmlFor="muscle_mass_diagnosis">Massa Muscular - Diagnóstico:</label>
                      <Field
                        as="textarea"
                        name="muscle_mass_diagnosis"
                        id="muscle_mass_diagnosis"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          setFieldValue('muscle_mass_diagnosis', e.target.value);
                          setDiagnosticoMassaMuscular(e.target.value); // Atualiza o estado do tricpsSkinfold
                        }}
                      />
                      <ErrorMessage name="muscle_mass_diagnosis" component="div" className="error-message" />
                    </div>*/}

                    <div className="anthropometry-form-group">
                      <label htmlFor="diagnosis">Diagnóstico:</label>
                      <Field
                        as="textarea"
                        name="diagnosis"
                        id="diagnosis"
                        onChange={(e) => {
                          setFieldValue('diagnosis', e.target.value);
                          setDiagnosis(e.target.value); // Atualiza o estado do tricpsSkinfold
                        }}
                      />
                      <ErrorMessage name="diagnosis" component="div" className="error-message" />
                    </div>
                  </Accordion>

                  {/* 
                  <Accordion title={"Outros Índices:"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_id">VET ID:</label>
                      <Field type="number" name="vet_id" id="vet_id" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_id')} />
                      <ErrorMessage name="vet_id" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_reduction_id">VET Redução ID:</label>
                      <Field type="number" name="vet_reduction_id" id="vet_reduction_id" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_reduction_id')} />
                      <ErrorMessage name="vet_reduction_id" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="bmi_classification_code">IMC Código de Classificação:</label>
                      <Field type="number" name="bmi_classification_code" id="bmi_classification_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'bmi_classification_code')} />
                      <ErrorMessage name="bmi_classification_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_final_equation">Equação Final VET:</label>
                      <Field type="number" name="vet_final_equation" id="vet_final_equation" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_final_equation')} />
                      <ErrorMessage name="vet_final_equation" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="formula_vet_code">Código da Fórmula VET:</label>
                      <Field type="number" name="formula_vet_code" id="formula_vet_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'formula_vet_code')} />
                      <ErrorMessage name="formula_vet_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="activity_factor_code">Código do Fator de Atividade:</label>
                      <Field type="number" name="activity_factor_code" id="activity_factor_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'activity_factor_code')} />
                      <ErrorMessage name="activity_factor_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="formula_fat_percentage_code">Código da Fórmula do Percentual de Gordura:</label>
                      <Field type="number" name="formula_fat_percentage_code" id="formula_fat_percentage_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'formula_fat_percentage_code')} />
                      <ErrorMessage name="formula_fat_percentage_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="pg_classification_code">Código de Classificação PG:</label>
                      <Field type="number" name="pg_classification_code" id="pg_classification_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'pg_classification_code')} />
                      <ErrorMessage name="pg_classification_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="formula_pt_code">Código da Fórmula PT:</label>
                      <Field type="number" name="formula_pt_code" id="formula_pt_code" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'formula_pt_code')} />
                      <ErrorMessage name="formula_pt_code" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="age_years">Idade Anos:</label>
                      <Field type="number" name="age_years" id="age_years" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'age_years')} />
                      <ErrorMessage name="age_years" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="age_months">Idade Meses:</label>
                      <Field type="number" name="age_months" id="age_months" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'age_months')} />
                      <ErrorMessage name="age_months" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="bray_classification">Código de Zunido:</label>
                      <Field type="number" name="bray_classification" id="bray_classification" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'bray_classification')} />
                      <ErrorMessage name="bray_classification" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="mcm">Massa Corporal Magra MCM:</label>
                      <Field type="number" name="mcm" id="mcm" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'mcm')} />
                      <ErrorMessage name="mcm" component="div" className="error-message" />
                    </div>
                  </Accordion>
                  
                  <Accordion title={"Fatores VET"} className="accordion" >
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_final">VET Final:</label>
                      <Field type="number" name="vet_final" id="vet_final" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_final')} />
                      <ErrorMessage name="vet_final" component="div" className="error-message" />
                    </div>
                    <div className="anthropometry-form-group">
                      <label htmlFor="vet_final_weight">VET Peso:</label>
                      <Field type="number" name="vet_final_weight" id="vet_final_weight" onKeyDown={(e) => onlyNumbers(e, setFieldValue, 'vet_final_weight')} />
                      <ErrorMessage name="vet_final_weight" component="div" className="error-message" />
                    </div>
                  </Accordion> */}

                </div>
                <div className="anthropometry-colC">
                  <div className="formButtons">
                    <button className='sendBtn' type="submit" disabled={isSubmitting}>Enviar</button>
                    <button className='backBtn' onClick={() => navigate('/home/anthropometry/get')}>Cancelar</button>
                  </div>
                </div>
              </div >
            </Form >
          )}
        </Formik >
        {showModal && (
          <ModalForms
            message={message}
            onConfirm={() => setShowModal(false)}
          />
        )}
      </div >
    </>
  );

};

export default CreateAnthropometry;
